<template>
  <div
    :class="`here py-5 px-5 position-relative ${
      $route.path.match('investidor') ? 'transparent' : bg_color
    }`"
  >
    <!-- <div
        id="userId"
        class="position-absolute d-flex j-c-center hoverStyleButton"
        content="Ual... Esse é o seu ID no SKAKI 😉"
        v-tippy="{ placement: 'right' }"
      >
        <span id="idSkaki" class="color-fff f-w-700"
          ><small>Esse é o seu ID no SKAKI</small>{{ meusDados.id }}</span
        >
      </div> -->

    <div :class="`px-0 ${container} border-default shadow-sm`">
      <div
        :class="`bg-fff chamaFormularioModal in-here position-relative ${
          !shadow_sm ? '' : 'shadow-sm'
        } ${px}`"
      >
        <div
          id="editarDados"
          class="position-absolute d-flex"
          v-if="$store.getters.getPerfil != 5 && btn_editar_meus_dados"
        >
          <div class="d-flex">
            <button
              @click="mudarSenha()"
              type="submit"
              :class="`btn-style-default-less color-fff text-uppercase ${
                editarDados ? 'bg-42cc7e' : 'bg-0e5caf'
              }`"
            >
              {{ editarDados ? "Salvar nova senha" : "Alterar senha" }}
            </button>
            <button
              v-if="editarDados"
              :class="`btn-style-default-less color-fff bg-d42338 ml-3 text-uppercase`"
              @click="cancelarMudarSenha()"
            >
              cancelar
            </button>
          </div>
          <div
            v-if="alterar_foto"
            id="addFoto"
            class="d-flex align-items-center justify-content-center"
            :style="
              $store.getters.getPerfil == 4 ? 'bottom: 20px;' : 'bottom: 170px;'
            "
          >
            <div class="d-flex mx-5">
              <input
                name=""
                class="btnAlteraFoto"
                type="file"
                @change="onChoseFile"
                id="file"
                accept="image/x-png, image/jpeg"
                placeholder="Alterar"
                hidden
                :disabled="$store.getters.getPerfil == 5"
              />
            </div>
          </div>
        </div>
        <div class="row h-100">
          <div class="col-md-8">
            <div class="row">
              <div class="h2 col-md-12">
                <h2 class="text-left mt-5 pb-2 title-dados">
                  {{ targetDoing }}
                </h2>
              </div>
              <div class="col-md-12 text-left">
                <label for="" class="mb-2 mt-4">Nome completo</label> <br />
                <input
                  type="text"
                  class="font-padrao"
                  placeholder="Primeiro Nome"
                  :value="$store.getters.getUserDatas.name"
                  disabled
                />
              </div>
              <!-- <div class="col-md-6 text-left">
                <label for="" class="mb-2 mt-4">Primeiro Nome</label> <br />
                <input
                  type="text"
                  class="font-padrao"
                  placeholder="Primeiro Nome"
                  :value="userFirstName()"
                  disabled
                />
              </div>
              <div class="col-md-6 text-left">
                <label for="lastName" class="mb-2 mt-4">Último Nome</label>
                <br />
                <input
                  type="text"
                  class="font-padrao"
                  id="lastName"
                  placeholder="Último Nome"
                  :value="userLastName()"
                  disabled
                />
              </div> -->

              <div class="col-md-6 text-left">
                <label for="" class="mb-2 mt-4">CPF</label> <br />
                <the-mask
                  type="text"
                  class="font-padrao"
                  :mask="['###.###.###-##']"
                  :value="meusDados.cpf"
                  disabled
                />
              </div>
              <div class="col-md-6 text-left">
                <label for="" class="mb-2 mt-4">E-mail</label> <br />
                <input
                  type="email"
                  class="font-padrao"
                  placeholder="Digite@seuEmail.com"
                  :value="meusDados.email"
                  disabled
                />
              </div>
              <div
                class="col-md-8 text-left"
                hidden
                v-if="$store.getters.getPerfil > 2"
              >
                <label for="" class="mb-2 mt-4">unidade</label>
                <br />
                <input
                  type="text"
                  placeholder="Sua unidade de ensino"
                  value="Escola Estadual Dom Bosco"
                  disabled
                />
              </div>
              <ValidationObserver
                ref="form"
                tag="form"
                class="mt-5 text-left col-md-12"
                @submit.prevent="mudarSenha()"
              >
                <div
                  class="row text-left"
                  v-if="$store.getters.getPerfil != 5 && editarDados"
                >
                  <ValidationProvider
                    v-slot="{ errors, ariaMsg, classes }"
                    rules="required|min:8"
                    name="Senha"
                    id="Senha"
                    :bails="false"
                    class="input-padding col-md-6"
                    tag="div"
                  >
                    <label for="">Senha</label> <br />
                    <input
                      type="password"
                      class="form-control"
                      :class="classes"
                      placeholder="Senha"
                      v-model="obj.password"
                      :disabled="!editarDados || $store.getters.getPerfil == 5"
                    />
                    <span v-bind="ariaMsg" class="span-status-validate">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors, ariaMsg, classes }"
                    rules="required|confirmed:Senha|:min:8"
                    name="'ConfirmarSenha'"
                    id="ConfirmarSenha"
                    :bails="false"
                    class="input-padding col-md-6"
                    tag="div"
                  >
                    <div :class="control">
                      <label for="">Cofirmar Senha</label> <br />
                      <input
                        class="form-control"
                        :class="classes"
                        type="password"
                        placeholder="Senha"
                        v-model="confirmaPassword"
                        :disabled="
                          !editarDados || $store.getters.getPerfil == 5
                        "
                      />
                    </div>
                    <span v-bind="ariaMsg" class="span-status-validate">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </ValidationObserver>
              <div
                v-if="!btn_editar_meus_dados"
                class="
                  btns
                  position-absolute
                  d-flex
                  align-items-center
                  justify-content-center
                  col-md-12
                "
              >
                <button
                  class="btn-style-default color-fff bg-d42338"
                  @click="hideModal('modalNameFormularioNovoAluno')"
                >
                  cancelar
                </button>
                <button
                  class="btn-style-default color-fff bg-0e5caf ml-3"
                  @click="salvarDadosFormulario()"
                >
                  salva e fechar
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <label
              for="file"
              class="
                color-0e5caf
                f-w-700
                text-uppercase
                hoverStyleButton
                mt-5
                pt-5
              "
            >
              <div v-if="getUserDatas.file">
                <img
                  id="fotoPerfilMeusDadosAluno"
                  :src="getUserDatas.file ? getUserDatas.file : obj.file_id"
                />
              </div>
              <div v-else>
                <img
                  id="fotoPerfilMeusDadosAluno"
                  src="./../../public/img/sem-foto.jpg"
                  alt=""
                />
              </div>
              <label
                for="file"
                v-if="$store.getters.getPerfil != 5"
                class="
                  color-0e5caf
                  f-w-700
                  text-uppercase
                  pt-3
                  hoverStyleButton
                "
                >alterar foto de Perfil</label
              >
            </label>
          </div>
        </div>
        <!---->
        <div class="row mt-0" v-if="$store.getters.getPerfil == 5">
          <div class="col-md-8">
            <div class="row mb-5">
              <div class="h2 col-md-12">
                <h2 class="text-left mt-5 pb-2 title-dados">
                  Dados do Responsável
                </h2>
              </div>
              <div class="col-md-12 text-left">
                <label for="nomeCompleto" class="mb-2 mt-4"
                  >Nome Completo</label
                >
                <br />
                <input
                  type="text"
                  class="font-padrao"
                  placeholder="Nome do Responsável"
                  :value="meusDados.nome_responsavel"
                  id="nomeCompleto"
                  disabled
                />
              </div>

              <div class="col-md-6 text-left">
                <label for="telefoneResp" class="mb-2 mt-4">Telefone</label>
                <input
                  type="text"
                  v-mask="[' (##) # #####-####']"
                  class="font-padrao"
                  placeholder="(99) 9 9999-9999"
                  :value="meusDados.telefone_responsavel"
                  id="telefoneResp"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="col-md-8" v-if="getUserDatas.profile_id === 5">
            <div class="row mb-5">
              <div class="h2 col-md-12">
                <h2 class="text-left mt-5 pb-2 title-dados">Conquistas</h2>
              </div>
              <div>
                <p v-if="conquistas.length === 0" style="margin-left: 14px">
                  Sem conquistas ainda.
                </p>
                <ul v-for="(conquista, index) in conquistas" :key="index">
                  <li>{{ conquista.conquista }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
            class="row mt-0"
            v-if="conquistasPendentes.length > 0 && getUserDatas.profile_id === 5"
          >
          <div class="col-md-8">
            <div class="row mb-5">
              <div class="h2 col-md-12">
                <h2 class="text-left mt-5 pb-2 title-dados">
                  Conquistas pendentes
                </h2>
              </div>
              <div>
                <ul
                  v-for="(conquista, index) in conquistasPendentes"
                  :key="index"
                >
                  <li>{{ conquista.conquista }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      conquistas: [],
      conquistasPendentes: [],
      editarDados: false,
      count: 1,
      meusDados: this.$store.getters.getUserDatas,
      confirmaPassword: null,
      obj: {
        id: this.$store.getters.getUserDatas.id,
        password: null,
        file_id: require("../assets/aluno/perfilDefault.png"),
      },
      dados: {
        desarquivo: "",
      },
    };
  },
  mounted() {
    if (this.getUserDatas.profile_id === 5) {
      this.getConquistas();
    }
  },
  computed: {
    ...mapGetters(["getUserDatas"]),
  },
  props: [
    "shadow_sm",
    "container",
    "bg_color",
    "targetDoing",
    "px",
    "alterar_foto",
    "btn_editar_meus_dados",
  ],
  methods: {
    async getConquistas() {
      const response = await fetch(`${this.VUE_APP_CMS}api/conquistas`, {
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
      });
      const responseJSON = await response.json();
      responseJSON.forEach((conquista) => {
        if (conquista.conquistado) this.conquistas.push(conquista);
        else this.conquistasPendentes.push(conquista);
      });
    },
    salvarDadosFormulario() {
      this.$emit("salvaFormulario");
    },
    onChoseFile: function (event) {
      // Reference to the DOM input element
      var input = event.target;

      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        if (Math.round(input.files[0].size / 1024) >= 5000) {
          this.toastGlobal(
            "success",
            "Seu arquivo é maior que 5MB! Por favor utilize um compressor ou selecione outro arquivo! \r"
          );
          return false;
        }
        var reader = new FileReader();
        reader.onload = (e) => {
          this.obj.file_id = e.target.result;
          this.$nextTick(() => {
            // this.$store.commit("SET_FOTO_PERFIL", input);
            this.sendFoto();
          });
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    sendFoto() {
      var postObj = new URLSearchParams();
      this.disabled = true;
      let pegaDados = {
        id: this.meusDados.id,
        file: this.obj.file_id,
      };

      this.$store.dispatch("editUser", pegaDados).then((resolve) => {
        if (resolve) {
          this.toastGlobal("success", "Foto alterada com sucesso!");
          this.$store
            .dispatch("buscaDadosUsuarioById", {
              token: this.$store.getters.getPerfilToken,
              id: this.meusDados.id,
            })
            .then((resolve) => {
              if (!resolve) {
                this.toastGlobal(
                  "error",
                  "Erro ao apresentar nova foto de perfil!"
                );
              }
            });
        } else {
          this.toastGlobal("success", "Houve algum problema, tente mais tarde");
        }
        this.disabled = false;
        this.count = 1;
      });
    },
    mudarSenha() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.count == 1) {
            this.editarDados = !this.editarDados;
            this.count += 1;
          } else {
            if (this.editarDados && this.count == 2) {
              this.$store.dispatch("editUser", this.obj).then((resolve) => {
                if (resolve.valid) {
                  this.toastGlobal(
                    "success",
                    "Sua senha foi alterada com sucesso"
                  );
                } else {
                  this.toastGlobal(
                    "error",
                    "Algo de errado ocorreu ao salvar sua senha"
                  );
                }
                this.cancelarMudarSenha();
              });
            }
          }
        }
      });
    },
    cancelarMudarSenha() {
      this.editarDados = !this.editarDados;
      this.count = 1;
    },
  },
};
</script>

<style scoped>
#userId {
  z-index: 10;
  border: 100%;
  background-color: #42cc7e;
  border-radius: 20px;
  margin: 10px;
  width: 30px;
  height: 30px;
}

small {
  display: none;
}

#userId span {
  font-size: 12px;
}

.less-width input {
  width: 75%;
}

input,
input::placeholder {
  font-size: 10px !important;
  color: #454c57 !important;
}

#addFoto label:hover {
  cursor: pointer;
}

#fotoPerfilMeusDadosAluno {
  background-color: #cbccd3;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  object-fit: cover;
  background-position: center;
}

.here h2 {
  border-bottom: 1px solid #e4e5e8;
  font-size: 14px;
}

#editarDados {
  bottom: 35px;
}
.font-padrao::placeholder {
  font-size: 12px !important;
}
input {
  /* Chrome/Opera/Safari */
  font-size: 12px !important;
}

li {
  text-align: left;
}
</style>
